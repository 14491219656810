import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite";
import { BrandBar, ButtonText } from "components";
import { colors, spacing, timing } from "common/styles/variables";

const HeaderMobile = props => {
  const { condensed } = props;
  const styles = StyleSheet.create({
    header: {
      backgroundColor: colors.bgBlack,
      transition: `all ${timing.slow}`,
      paddingTop: "0px"
    },
    logo: {
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      alignItems: "center"
    },
    brandLogo: {
      width: "100%",
      display: "block",
      transition: `all ${timing.slow}`
    },
    main: {
      paddingTop: spacing.space1,
      paddingBottom: spacing.space1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: `all ${timing.slow}`
    }
  });

  return (
    <Fragment>
      {/* BrandBar height changed due to branding request. Keeping it here because element will be changed in future */}
      <BrandBar fixed height={!condensed ? 0 : 0} />
      <header className={css(styles.header)}>
        <div className={css(styles.brandLogo)}>
          <div className="container">
            <div className={css(styles.main)}>
              <div className={"mr-auto"}>
                {props.renderMenu ? props.renderMenu() : null}
              </div>
              <div className={css(styles.logo)}>
                {props.renderLogo ? props.renderLogo() : null}
              </div>
              <div className={"ml-auto"}>
                <ButtonText
                  size="large"
                  iconBefore="map"
                  color="gray"
                  iconColor="gray"
                  href="http://americanswiss.co.za/store-locator/"
                  target="_blank"
                />
                {props.renderCart ? props.renderCart() : null}
              </div>
            </div>
          </div>
        </div>

        {props.renderNavbar ? props.renderNavbar() : null}
      </header>
    </Fragment>
  );
};

HeaderMobile.defaultProps = {
  color: "Copy",
  condensed: false
};

export default HeaderMobile;
