import React, { Component } from "react";
import Slider from "react-slick";
import "./slider.css";
import { Icon } from "components";
import { colors, spacing } from "common/styles/variables";

export default class SliderWrapper extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      draggable: false,
      speed: 1000,
      slidesToShow: this.props.slidesToShow,
      slidesToScroll: 1,
      centerMode: false,
      adaptiveHeight: true,
      arrows: false,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 993,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            draggable: true,
            centerPadding: "80px"
          }
        }
      ]
    };

    return [
      <div style={{ position: "relative", zIndex: 10 }}>
        <SliderArrows
          arrowsTop={this.props.arrowsTop}
          next={this.next}
          previous={this.previous}
        />
        <div className={this.props.isContained ? "container-fluid px-5" : null}>
          <Slider ref={c => (this.slider = c)} {...settings}>
            {this.props.children}
          </Slider>
        </div>
      </div>
    ];
  }
}

class SliderArrows extends React.Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
          position: "absolute",
          width: "100%",
          top: this.props.arrowsTop,
          right: "0px",
          zIndex: "9"
        }}
      >
        <NextArrow onClick={this.props.previous} />
        <PrevArrow onClick={this.props.next} />
      </div>
    );
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        ...style,
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        border: "1px solid",
        borderColor: colors.borderBase,
        background: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: spacing.space1
      }}
    >
      <Icon name="arrow-left" height="17px" color={colors.brandBlack} />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        border: "1px solid",
        borderColor: colors.borderBase,
        background: "transparent",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: spacing.space1
      }}
      onClick={onClick}
    >
      <Icon name="arrow-right" height="17px" color={colors.brandBlack} />
    </div>
  );
}

SliderArrows.defaultProps = {
  arrowsTop: "-80px"
};

SliderWrapper.defaultProps = {
  slidesToShow: 6,
  isContained: false
};
