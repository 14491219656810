import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import {
  fonts,
  fontStyles,
  fontWeight,
  letterSpacing,
  colors,
  spacing
} from "common/styles/variables";

const hoverStyles = {
  base: {
    borderBottom: "1px solid"
  }
};

const styles = {
  base: {
    fontFamily: fonts.sans,
    lineHeight: 1,
    margin: 0,
    padding: 0,
    border: "none",
    background: "none"
  },

  size: {
    mini: {
      ...fontStyles("9px", `${9 * 2}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${9 * 1}px`, `${9 * 2}px`)
      }
    },
    tiny: {
      ...fontStyles("12px", `${12 * 1.5}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${12 * 1}px`, `${12 * 1.25}px`)
      }
    },
    small: {
      ...fontStyles("13px", `${13 * 1.5}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${13 * 1}px`, `${13 * 1.25}px`)
      }
    },
    medium: {
      ...fontStyles("15px", `${15 * 1.5}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${15 * 1}px`, `${15 * 1.25}px`)
      }
    },
    large: {
      ...fontStyles("16px", `${16 * 1.5}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${16 * 1}px`, `${16 * 1.25}px`)
      }
    }
  },

  mb: {
    none: {
      marginBottom: spacing.space0
    },
    small: {
      marginBottom: spacing.space1
    },
    medium: {
      marginBottom: spacing.space2
    },
    large: {
      marginBottom: spacing.space4
    }
  },

  weight: {
    light: {
      fontWeight: fontWeight.light
    },
    regular: {
      fontWeight: fontWeight.regular
    }
  },

  color: {
    base: {
      color: colors.textBase
    },
    white: {
      color: colors.textWhite
    },
    light: {
      color: colors.textLight
    },
    dark: {
      color: colors.textDark
    },
    error: {
      color: colors.statusDanger
    }
  },

  hover: {
    cursor: "pointer",
    borderBottom: "1px solid transparent",
    ":hover": hoverStyles.base,
    ":focus": hoverStyles.base,
    ":active": hoverStyles.base
  },

  variant: {
    sans: {
      fontFamily: fonts.sans
    },
    serif: {
      fontFamily: fonts.serif
    },
    uppercase: {
      textTransform: "uppercase"
    },
    truncate: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    left: {
      textAlign: "left"
    },
    right: {
      textAlign: "right"
    },
    center: {
      textAlign: "center"
    },
    justify: {
      textAlign: "justify"
    }
  },

  tracking: {
    none: {
      letterSpacing: letterSpacing.none
    },
    small: {
      letterSpacing: letterSpacing.small
    },
    medium: {
      letterSpacing: letterSpacing.medium
    }
  }
};

const Copy = props => {
  const {
    color,
    children,
    hover,
    html,
    mb,
    override,
    size,
    sans,
    serif,
    truncate,
    tracking,
    uppercase,
    intro,
    left,
    right,
    center,
    justify,
    weight,
    isParent,
    as
  } = props;

  let Element = "";

  if (isParent) {
    Element = "span";
  } else {
    Element = as;
  }

  const style = [
    styles.base,
    size && styles.size[size],
    sans && styles.variant.sans,
    serif && styles.variant.serif,
    mb && styles.mb[mb],
    hover && styles.hover,
    weight && styles.weight[weight],
    color && styles.color[color],
    truncate && styles.variant.truncate,
    uppercase && styles.variant.uppercase,
    left && styles.variant.left,
    right && styles.variant.right,
    center && styles.variant.center,
    justify && styles.variant.justify,
    tracking && styles.tracking[tracking],
    override && override
  ];

  const temp = StyleSheet.create({
    copy: style.reduce((result, item) => {
      if (item) {
        return {
          ...result,
          ...item
        };
      }
      return result;
    }, {})
  });

  if (html) {
    return (
      <div
        className={`${css(temp.copy)} ${intro ? "intro" : ""}`}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return (
    <Element onClick={props.onClick} className={css(temp.copy)}>
      {children}
    </Element>
  );
};

Copy.defaultProps = {
  color: "base",
  mb: "medium",
  override: {},
  size: "medium",
  tracking: "small",
  sans: true,
  serif: false,
  isParent: false,
  truncate: false,
  uppercase: false,
  left: true,
  right: false,
  center: false,
  justify: false,
  weight: "light",
  intro: false,
  hover: false,
  onClick: null,
  as: "p"
};

Copy.propTypes = {
  /** The copy color */
  color: PropTypes.oneOf([
    "base",
    "blue",
    "textBase",
    "light",
    "lightO",
    "white",
    "error"
  ]),
  /** Text for the copy */
  children: PropTypes.node,
  /** Margin bottom  */
  mb: PropTypes.oneOf(["none", "tiny", "small", "medium", "large"]),
  /** Override styles */
  override: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  /** If the Copy container is a parent of other <p> tags then make the component a 'span' instead*/
  isParent: PropTypes.bool,
  /** Declares the font size of the heading */
  tracking: PropTypes.oneOf(["none", "small", "medium"]),
  /** Declares the font size of the Copy */
  size: PropTypes.oneOf(["mini", "tiny", "small", "medium", "large"]),
  /** Whether or not to style the first <p> difeerntly with .css */
  intro: PropTypes.bool,
  /** Whether or not to change the style on hover */
  hover: PropTypes.bool,
  /** Whether or not to hide the text overflow with an ellipsis */
  truncate: PropTypes.bool,
  /** Whether or not to set the copy in all caps */
  uppercase: PropTypes.bool,
  /** Whether or not to align left */
  left: PropTypes.bool,
  /** Whether or not to align right */
  right: PropTypes.bool,
  /** Whether or not to align center */
  center: PropTypes.bool,
  /** Adjusts the font weight of the copy */
  weight: PropTypes.oneOf(["bold", "medium", "regular", "light"])
};

Copy.styles = styles;

export default Copy;
