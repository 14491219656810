import React, { Component } from "react";
import PropTypes from "prop-types";
import { PHeader } from "components";
import { StyleSheet, css } from "aphrodite";
import { colors } from "common/styles/variables";

const styles = {
  module: {
    border: `1px solid ${colors.borderBase}`
  }
};

const Module = props => {
  const {
    title,
    subtitle,
    description,
    parent,
    children,
    onClose,
    onClick
  } = props;

  const baseStyles = StyleSheet.create({
    module: {
      ...styles.module
    }
  });

  return (
    <div className={css(baseStyles.module)}>
      <PHeader
        parent={parent}
        close={close}
        title={title}
        subtitle={subtitle}
        description={description}
        onClick={onClick}
        onClose={onClose}
      />
      {children && children}
    </div>
  );
};

Module.defaultProps = {
  children: null,
  title: null,
  close: false,
  parent: false
};

Module.propTypes = {
  /** Title of Module */
  title: PropTypes.string.isRequired,
  /** Children of Module */
  children: PropTypes.node,
  /** Should there be an option to Close the header? */
  close: PropTypes.bool,
  /** Is this a child Module? */
  parent: PropTypes.bool
};

export default Module;
