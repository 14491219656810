import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing, timing } from "common/styles/variables";
import { Copy, Image, Icon } from "components";

const styles = {
  base: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: spacing.space1,
    transition: timing.slow,
    borderTop: `1px solid ${colors.borderBase}`,
    borderRight: `1px solid ${colors.borderBase}`,
    height: "100%",
    ":hover": {
      cursor: "pointer",
      backgroundColor: colors.bgBase
    }
  },
  box: {
    position: "relative",
    height: "100%"
    // margin: spacing.space05
  },
  icon: {
    background: colors.bgBase,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    border: `1px solid ${colors.borderBlack}`,
    position: "absolute",
    top: "5px",
    right: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: timing.slow,
    ":hover": {
      cursor: "pointer",
      color: colors.brandWhite,
      background: colors.brandBlack
    }
  },
  image: {
    // width: "35px",
    marginBottom: spacing.space1
  },
  active: {
    // border: `1px solid ${colors.borderDark}`,
    backgroundColor: colors.bgBase
  }
};

const OptionImage = props => {
  const { active, image, title, onClick, onRemove, justify = null } = props;

  const baseStyles = StyleSheet.create({
    optionImage: {
      ...styles.base,
      ...(active && styles.active)
    },
    image: {
      ...styles.image
    },
    icon: {
      ...styles.icon
    },
    box: {
      ...styles.box,
      ...(justify ? { justifyContent: justify } : {})
    }
  });

  return (
    <div className={css(baseStyles.box)}>
      <div
        className={`single--imageOption ${css(baseStyles.optionImage)}`}
        onClick={onClick}
      >
        <div className={css(baseStyles.image)}>
          <Image imageUrl={image} />
        </div>
        <Copy size="tiny" mb="none" color="dark" center>
          {title}
        </Copy>
      </div>
      {active && onRemove && (
        <div className={css(baseStyles.icon)} onClick={onRemove}>
          <Icon mr="0" ml="0" name="close" fs="12px" height="12px" />
        </div>
      )}
    </div>
  );
};

OptionImage.defaultProps = {
  active: false,
  title: null,
  image: null
};

OptionImage.propTypes = {
  /** Is the option active? */
  active: PropTypes.bool,
  /** Title of the Image */
  title: PropTypes.string,
  /** URL of the Image */
  image: PropTypes.string
};

OptionImage.styles = styles;

export default OptionImage;
