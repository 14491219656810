import React from "react";
import { StyleSheet, css } from "aphrodite";
import { styleConvert } from "../../utils/helpers";
import Media from "react-media";
import { Border, BrandBar, Copy, Icon, Navbar, NavItem } from "components";
import {
  fonts,
  fontStyles,
  fontWeight,
  colors,
  spacing,
  letterSpacing,
  opacity
} from "common/styles/variables";

const Footer = props => {
  const { children } = props;

  const styles = {
    footer: {
      backgroundColor: colors.bgBlack
    },
    menus: {
      paddingTop: spacing.space2,
      paddingBottom: spacing.space2,
      display: "inline-block",
      width: "100%",
      "@media (min-width: 576px)": {
        paddingTop: spacing.space6,
        paddingBottom: spacing.space6
      }
    },
    legal: {
      paddingTop: spacing.space2,
      paddingBottom: spacing.space2,
      borderTop: `1px solid ${colors.borderDark}`,
      display: "inline-block",
      width: "100%",
      "@media (min-width: 576px)": {
        paddingTop: spacing.space3,
        paddingBottom: spacing.space3
      }
    }
  };

  const footer = [styles.footer];
  const menus = [styles.menus];
  const legal = [styles.legal];

  const baseStyles = StyleSheet.create({
    footer: styleConvert(footer),
    menus: styleConvert(menus),
    legal: styleConvert(legal)
  });

  return (
    <footer className={css(baseStyles.footer)}>
      <div className={css(baseStyles.menus)}>
        <div className="container">
          <Copy center size="small" color="light" tracking="none">
            Prices correct at time of publishing. Merchandise not shown to
            actual size.
          </Copy>
          {props.renderNavbarSocial ? props.renderNavbarSocial() : null}
        </div>
      </div>

      <div className={css(baseStyles.legal)}>
        <div className="container">
          {props.renderNavbar ? props.renderNavbar() : null}
          <Border color={"black"} mt="none" mb="none" />
          <Copy center size="small" color="light" tracking="none" mb="none">
            {props.footerLegalText ? props.footerLegalText : null}
          </Copy>
          <Copy
            center
            size="small"
            color="light"
            tracking="none"
            html={props.footerAttribution && props.footerAttribution}
          />
        </div>
      </div>

      {/* BrandBar removed due to branding request. Keeping it here because element will be changed in future */}
      {/* <Media
        query="(min-width: 576px)"
        render={() => <BrandBar/>}
      /> */}
    </footer>
  );
};

Footer.defaultProps = {
  color: "Copy"
};

export default Footer;
