import React from "react";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing, timing } from "../../../common/styles/variables";

const Navbar = props => {
  const { vertical } = props;

  const styles = StyleSheet.create({
    navbar: {
      backgroundColor: props.background,
      paddingTop: props.padding,
      paddingBottom: props.padding,
      width: "100%",
      display: "flex",
      alignItems: "center",
      transition: `transform ${timing.slow}`,
      ...props.custom
    },
    container: {
      "@media (max-width: 1199px)": {
        width: "100%"
      }
    },
    ul: {
      width: "100%",
      marginBottom: 0,
      paddingLeft: 0,
      display: "flex",
      flexDirection: vertical ? "column" : props.flexDirection,
      justifyContent: vertical ? "flex-start" : props.justifyContent,
      alignItems: vertical ? "center" : props.alignItems
    }
  });

  return (
    <nav className={css(styles.navbar)}>
      <div className={`${css(styles.container)} container`}>
        <ul className={css(styles.ul)}>{props.children}</ul>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  background: colors.baseTransparent,
  padding: spacing.space4,
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  custom: null
};

export default Navbar;
