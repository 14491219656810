import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing, timing } from "common/styles/variables";
import { Copy } from "components";

const styles = {
  base: {
    border: `1px solid ${colors.borderBase}`,
    backgroundColor: colors.bgWhite,
    borderRadius: spacing.space2,
    paddingLeft: spacing.space2,
    paddingRight: spacing.space2,
    marginRight: "2px",
    marginLeft: "2px",
    marginBottom: spacing.space2,
    minWidth: "43.66px",
    transition: timing.slow,
    ":hover": {
      cursor: "pointer",
      boxShadow: colors.borderShadow
    }
  },
  active: {
    border: `1px solid ${colors.borderDark}`
  }
};

const OptionText = props => {
  const { active, children, onClick } = props;

  const baseStyles = StyleSheet.create({
    optionText: {
      ...styles.base,
      ...(active && styles.active)
    }
  });

  return (
    <div className={css(baseStyles.optionText)} onClick={onClick}>
      <Copy uppercase mb="none" tracking="medium" size="mini" center>
        {children}
      </Copy>
    </div>
  );
};

OptionText.defaultProps = {
  active: false,
  title: null,
  image: null
};

OptionText.propTypes = {
  /** Is the option active? */
  active: PropTypes.bool,
  /** Title of the Option */
  title: PropTypes.string
};

OptionText.styles = styles;

export default OptionText;
