import { spacing, colors } from 'common/styles/variables';
import color from "color";
import * as R from "ramda";

/** Hover Attributes */
export const getHoverAttributes = (args, colors) => {
  if (!args) return null;

  const styles = Object.keys(args).reduce((result,item) => {
    result[item] = colors[args[item]];
    return result;
  },{})

  return {
    ':hover': {
      outline: 0,
      ...styles,
    },
    ':focus': {
      outline: 0,
      ...styles,
    },
    ':active': {
      outline: 0,
      ...styles,
    },
  }
}


/** Convert Hex to RGBA */
export const rgba = (hex, opacity) => {
  return color(hex).alpha(opacity);
}

// Concat Styles and Media queries
export const styleConvert = (arr) => arr.reduce((result, item) => {
  if (item) {
    return R.mergeDeepRight(result, item);
  }
  return result;
},{})
