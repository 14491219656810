import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing } from "common/styles/variables";

const styles = {
  base: {
    padding: spacing.space2,
    marginBottom: spacing.space2,
    // paddingLeft: spacing.space2,
    // paddingRight: spacing.space2,
    // paddingBottom: spacing.space2,
    backgroundColor: colors.bgWhite,
    borderLeft: `1px solid ${colors.borderBase}`,
    borderRight: `1px solid ${colors.borderBase}`,
    borderBottom: `1px solid ${colors.borderBase}`
  }
};

const Footer = props => {
  const { children } = props;

  const baseStyles = StyleSheet.create({
    footer: {
      ...styles.base
    }
  });

  return <div className={css(baseStyles.footer)}>{children}</div>;
};

Footer.defaultProps = {
  children: null
};

Footer.propTypes = {
  /** Children of Footer */
  children: PropTypes.string
};

Footer.styles = styles;

export default Footer;
