import React from "react";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing } from "common/styles/variables";

const Tabs = props => {
  const styles = {
    base: {
      display: "flex",
      justifyContent: props.justifyContent,
      alignItems: "center"
    },
    hasBorder: {
      borderLeft: `1px solid ${colors.borderBase}`,
      borderRight: `1px solid ${colors.borderBase}`,
      borderBottom: `1px solid ${colors.borderBase}`,
      paddingTop: spacing.space3,
      paddingLeft: spacing.space2,
      paddingRight: spacing.space2,
      paddingBottom: spacing.space3
    },
    hasMargin: {
      marginBottom: spacing.space2
    }
  };

  const { children, hasBorder, hasMargin } = props;

  const baseStyles = StyleSheet.create({
    tabs: {
      ...styles.base,
      ...(hasBorder && styles.hasBorder),
      ...(hasMargin && styles.hasMargin)
    }
  });

  return <div className={css(baseStyles.tabs)}>{children}</div>;
};

Tabs.defaultProps = {
  children: null,
  justifyContent: "space-around",
  hasBorder: false,
  hasMargin: false
};

Tabs.propTypes = {};

export default Tabs;
