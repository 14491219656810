import React from "react";
import PropTypes from "prop-types";
import {
  colors,
  fontStyles,
  timing,
  fonts,
  fontWeight,
  letterSpacing,
  spacing
} from "common/styles/variables";
import { rgba } from "../../utils/helpers";
import { Icon } from "components";
import { StyleSheet, css } from "aphrodite";

const hoverStyles = {
  base: {
    textDecoration: "none"
  },

  black: {
    base: {
      backgroundColor: colors.brandWhite,
      borderColor: colors.borderBlack,
      color: colors.brandBlack
    },
    outline: {
      backgroundColor: colors.baseTransparent,
      borderColor: colors.borderBlack,
      color: colors.brandBlack
    }
  },

  gray: {
    base: {
      backgroundColor: colors.brandWhite,
      borderColor: colors.borderBase,
      color: colors.brandGray
    },
    outline: {
      backgroundColor: colors.baseTransparent,
      borderColor: colors.borderBlack,
      color: colors.brandGray
    }
  },

  white: {
    base: {
      backgroundColor: colors.brandBlack,
      borderColor: colors.borderWhite,
      color: colors.brandWhite
    },
    outline: {
      backgroundColor: colors.baseTransparent,
      borderColor: colors.borderLight,
      color: colors.brandWhite
    }
  }
};

const styles = {
  base: {
    position: "relative",
    appearance: "none",
    backfaceVisibility: "hidden",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: colors.baseTransparent,
    borderRadius: 0,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    lineHeight: 1,
    paddingLeft: "30px",
    paddingRight: "30px",
    textAlign: "center",
    textDecoration: "none",
    fontFamily: fonts.sans,
    fontWeight: fontWeight.regular,
    letterSpacing: letterSpacing.medium,
    textTransform: "uppercase",
    transition: `color ${timing.slow} ease-in-out,
        background-color ${timing.slow} ease-in-out,
        border-color ${timing.slow} ease-in-out,
        opacity ${timing.slow} ease-in-out`,
    verticalAlign: "middle",
    whiteSpace: "nowrap",

    ":hover": hoverStyles.base,
    ":active": hoverStyles.base,
    ":focus": hoverStyles.base
  },

  color: {
    black: {
      button: {
        backgroundColor: colors.brandBlack,
        color: colors.brandWhite,
        borderColor: colors.borderBlack,

        ":hover": hoverStyles.black.base,
        ":focus": hoverStyles.black.base,
        ":active": hoverStyles.black.base
      }
    },
    white: {
      button: {
        backgroundColor: colors.brandWhite,
        color: colors.textBase,
        borderColor: colors.borderWhite,

        ":hover": hoverStyles.white.base,
        ":focus": hoverStyles.white.base,
        ":active": hoverStyles.white.base
      }
    },
    gray: {
      button: {
        backgroundColor: colors.brandGray,
        color: colors.brandWhite,
        borderColor: colors.borderBase,

        ":hover": hoverStyles.gray.base,
        ":focus": hoverStyles.gray.base,
        ":active": hoverStyles.gray.base
      }
    }
  },

  outline: {
    black: {
      button: {
        backgroundColor: colors.baseTransparent,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.borderBase,
        color: colors.brandBlack,

        ":hover": hoverStyles.black.outline,
        ":focus": hoverStyles.black.outline,
        ":active": hoverStyles.black.outline
      }
    },
    white: {
      button: {
        backgroundColor: colors.baseTransparent,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.borderBase,
        color: colors.brandWhite,

        ":hover": hoverStyles.white.outline,
        ":focus": hoverStyles.white.outline,
        ":active": hoverStyles.white.outline
      }
    },
    gray: {
      button: {
        backgroundColor: colors.baseTransparent,
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.borderBase,
        color: colors.brandGray,

        ":hover": hoverStyles.gray.outline,
        ":focus": hoverStyles.gray.outline,
        ":active": hoverStyles.gray.outline
      }
    }
  },

  size: {
    small: {
      button: {
        fontSize: "11px",
        paddingBottom: "10px",
        paddingTop: "10px",
        paddingLeft: "32px",
        paddingRight: "32px",
        iconBefore: {
          paddingRight: "16px",
          paddingLeft: "16px"
        },
        iconAfter: {
          paddingLeft: "16px",
          paddingRight: "16px"
        }
      },
      icon: {
        base: {
          fontSize: "14px"
        },
        iconBefore: {
          marginRight: "12px"
        },
        iconAfter: {
          marginLeft: "12px"
        }
      }
    },
    medium: {
      button: {
        fontSize: "11px",
        paddingBottom: "18px",
        paddingTop: "18px",
        paddingLeft: "40px",
        paddingRight: "40px",
        iconBefore: {
          paddingRight: "20px",
          paddingLeft: "20px"
        },
        iconAfter: {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      },
      icon: {
        base: {
          fontSize: "14px"
        },
        iconBefore: {
          marginRight: "18px"
        },
        iconAfter: {
          marginLeft: "18px"
        }
      }
    },
    large: {
      button: {
        fontSize: "11px",
        paddingBottom: "18px",
        paddingTop: "18px",
        paddingLeft: "40px",
        paddingRight: "40px",
        iconBefore: {
          paddingRight: "20px",
          paddingLeft: "20px"
        },
        iconAfter: {
          paddingLeft: "20px",
          paddingRight: "20px"
        }
      },
      icon: {
        base: {
          fontSize: "14px"
        },
        iconBefore: {
          marginRight: "24px"
        },
        iconAfter: {
          marginLeft: "24px"
        }
      }
    }
  },

  type: {
    full: {
      justifyContent: "center",
      width: "100%"
    }
  },

  variant: {
    center: {
      marginLeft: "auto",
      marginRight: "auto"
    },
    left: {
      marginRight: "auto"
    },
    right: {
      marginLeft: "auto"
    }
  },

  disabled: {
    cursor: "not-allowed",
    opacity: 0.5
  },

  icon: {
    margin: 0,
    padding: 0,
    color: "inherit"
  }
};

const Button = props => {
  const {
    href,
    children,
    onClick,
    color,
    center,
    left,
    right,
    size,
    text,
    outline,
    full,
    disabled,
    customStyles,
    iconBefore,
    iconAfter,
    mr,
    ml,
    variant
  } = props;
  const Element = href ? "a" : "button";
  const role = Element === "a" ? "button" : null;

  const baseStyles = StyleSheet.create({
    button: {
      ...styles.base,
      ...(color && styles.color[color].button),
      ...(size && styles.size[size].button),
      ...(iconBefore && size && styles.size[size].button.iconBefore),
      ...(iconAfter && size && styles.size[size].button.iconAfter),
      ...(outline && styles.outline[color].button),
      ...(full && styles.type.full),
      ...(center && styles.variant.center),
      ...(left && styles.variant.left),
      ...(right && styles.variant.right),
      marginRight: props.mr,
      marginLeft: props.ml,
      ...(customStyles && customStyles),
      ...(disabled && styles.disabled),
      ...(props.hover && outline ? { ...hoverStyles[color].outline } : {}),
      ...(props.hover && !outline ? { ...hoverStyles[color].base } : {})
    },
    iconBefore: {
      ...styles.icon,
      ...(size && styles.size[size].icon.base),
      ...(size && iconBefore && styles.size[size].icon.iconBefore),
      ...(iconBefore && outline && styles.outline[color].icon)
    },
    iconAfter: {
      ...styles.icon,
      ...(size && styles.size[size].icon.base),
      ...(size && iconAfter && styles.size[size].icon.iconAfter),
      ...(iconAfter && outline && styles.outline[color].icon)
    }
  });

  return (
    <Element
      className={css(baseStyles.button)}
      href={href}
      onClick={onClick}
      role={role}
      disabled={disabled}
    >
      {iconBefore && <Icon style={baseStyles.iconBefore} name={iconBefore} />}
      {children && <span>{children}</span>}
      {iconAfter && <Icon style={baseStyles.iconAfter} name={iconAfter} />}
    </Element>
  );
};

Button.defaultProps = {
  href: null,
  onClick: null,
  color: "black",
  center: false,
  size: "small",
  full: false,
  children: "",
  disabled: false,
  customStyles: null,
  iconBefore: null,
  iconAfter: null,
  mr: spacing.space0,
  ml: spacing.space0
};

Button.propTypes = {
  /** Pass an href prop to make the button an `a` element instead of a `button` */
  href: PropTypes.string,
  /** Content for the button */
  children: PropTypes.node.isRequired,
  /** Should button be aligned center - needs a flex wrapper*/
  center: PropTypes.bool,
  /** Should button be aligned left - needs a flex wrapper*/
  left: PropTypes.bool,
  /** Should button be aligned right - needs a flex wrapper */
  right: PropTypes.bool,
  /** Function to run when the button is clicked */
  onClick: PropTypes.func,
  /** Color of the button */
  color: PropTypes.oneOf(["black", "white", "gray"]),
  /** Size of the button */
  size: PropTypes.oneOf(["small", "medium", "large"]),
  /** Use a outline button */
  outline: PropTypes.bool,
  /** Allow button to span available width */
  full: PropTypes.bool,
  /** Special styles passed in props */
  customStyles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  /** Disable button */
  disabled: PropTypes.bool,
  /** Icon name to appear before text */
  iconBefore: PropTypes.string,
  /** Icon name to appear after text */
  iconAfter: PropTypes.string,
  /** Margin right in Spacing value as per variables.js */
  mr: PropTypes.string,
  /** Margin left in Spacing value as per variables.js */
  ml: PropTypes.string
};

Button.styles = styles;

export default Button;
