export const fonts = {
  serif: "'adobe-caslon-pro', Georgia, 'Times New Roman', Times, serif",
  sans: "europa, 'Helvetica Neue', Helvetica, Arial, sans-serif"
};

export const timing = {
  base: ".1s",
  slow: ".5s",
  slower: ".75s"
};

export const fontWeight = {
  bold: "700",
  medium: "500",
  regular: "400",
  light: "300"
};

export const fontStyles = (size, lh, extras = {}) => ({
  fontSize: size,
  lineHeight: lh,
  ...extras
});

export const letterSpacing = {
  none: "0",
  small: "1px",
  medium: "1.5px"
};

export const colors = {
  baseBlack: "#000000",
  baseInherit: "inherit",
  baseTransparent: "transparent",
  baseWhite: "#FFFFFF",

  brandBlack: "#000000",
  brandBlackHover: "#000000",
  brandGray: "#ACACAC",
  brandGrayHover: "#ACACAC",
  brandWhite: "#FFFFFF",
  brandWhiteHover: "#FFFFFF",

  borderBase: "#EEEEEE",
  borderBlack: "#000000",
  borderLight: "#B2B2B2",
  borderDark: "#2E2E2E",
  borderWhite: "#FFFFFF",
  borderShadow: "0px 0px 4px rgba(0,0,0,0.2)",

  bgLight: "#F9F9F9",
  bgBase: "#EDEDED",
  bgBlack: "#000000",
  bgBlackO: "rgba(0,0,0,0.70)",
  bgDark: "#AFB8BE",
  bgWhite: "#FFFFFF",
  bgWhiteO: "rgba(255,255,255,0.70)",

  linkBase: "#333333",
  linkHover: "#333333",

  statusDanger: "#B0153C",
  statusInfo: "#85CEFF",
  statusNeutral: "#333333",
  statusSuccess: "#52A74B",
  statusWarning: "#F5A623",

  textBase: "#000000",
  textBaseHover: "#999999",
  textLight: "#999999",
  textLightHover: "#000000",
  textLightO: "rgba(255,255,255,0.8)",
  textDark: "#666666",
  textDarkHover: "#666666",
  textWhite: "#FFFFFF",
  textWhiteHover: "#FFFFFF"
};

export const icons = {
  none: "",
  hint: "hint",
  menu: "menu",
  close: "close",
  map: "map",
  done: "done",
  edit: "edit",
  arrowRight: "arrow-right",
  arrowLeft: "arrow-left",
  angleLeft: "angle-left",
  angleRight: "angle-right",
  angleDown: "angle-down",
  angleUp: "angle-up",
  caretLeft: "caret-left",
  caretRight: "caret-right",
  caretDown: "caret-down",
  caretUp: "caret-up",
  save: "save",
  mobile: "info",
  youtube: "youtube",
  twitter: "twitter",
  facebook: "facebook",
  instagram: "instagram",
  shoppingCart: "instagram"
};

export const opacity = {
  solid: "1",
  hover: ".75",
  transparent: "0"
};

const spacingFactor = 8;
export const spacing = {
  space0: `${spacingFactor * 0}px`, // 0
  space05: `${spacingFactor / 2}px`, // 4
  space1: `${spacingFactor * 1}px`, // 8
  space2: `${spacingFactor * 2}px`, // 16
  space3: `${spacingFactor * 3}px`, // 24
  space4: `${spacingFactor * 4}px`, // 32
  space5: `${spacingFactor * 5}px`, // 40
  space6: `${spacingFactor * 6}px`, // 48
  space7: `${spacingFactor * 7}px`, // 56
  space8: `${spacingFactor * 8}px`, // 64
  space9: `${spacingFactor * 9}px`, // 72
  space10: `${spacingFactor * 10}px`, // 80
  space11: `${spacingFactor * 11}px`, // 88
  space12: `${spacingFactor * 12}px`, // 96
  space13: `${spacingFactor * 13}px`, // 104
  space14: `${spacingFactor * 14}px`, // 168
  space15: `${spacingFactor * 15}px` // 120
};

export const media = {
  min: {
    1430: "1430px",
    1410: "1410px",
    1350: "1350px",
    1290: "1290px",
    1200: "1200px",
    1080: "1080px",
    1024: "1024px",
    960: "960px",
    840: "840px",
    768: "768px",
    720: "720px",
    600: "600px",
    560: "560px",
    480: "480px",
    360: "360px",
    320: "320px"
  },
  max: {
    1530: "1530px",
    1430: "1430px",
    1410: "1410px",
    1350: "1350px",
    1290: "1290px",
    1200: "1200px",
    1080: "1080px",
    1024: "1024px",
    960: "960px",
    840: "840px",
    768: "768px",
    720: "720px",
    600: "600px",
    560: "560px",
    480: "480px",
    360: "360px",
    320: "320px"
  }
};
