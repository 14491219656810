import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing, fonts } from "common/styles/variables";
import { Heading, Icon, Copy } from "components";

const styles = {
  base: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerWrapper: {
    paddingTop: spacing.space3,
    paddingLeft: spacing.space2,
    paddingRight: spacing.space2,
    paddingBottom: spacing.space3,
    marginTop: "-1px",
    borderTop: `1px solid ${colors.borderBase}`,
    zIndex: "9"
  },
  parent: {
    paddingLeft: spacing.space2,
    paddingTop: spacing.space3,
    paddingBottom: spacing.space2,
    fontFamily: fonts.serif,
    position: "relative",
    zIndex: "99"
  }
};

const Header = props => {
  const {
    title,
    subtitle,
    description,
    muted,
    hover,
    parent,
    onClick,
    onClose,
    children,
    icon
  } = props;

  const baseStyles = StyleSheet.create({
    headerWrapper: {
      ...styles.headerWrapper,
      ...(parent && styles.parent)
    },
    header: {
      ...styles.base
    }
  });

  return (
    <div className={css(baseStyles.headerWrapper)}>
      <div
        className={css(baseStyles.header)}
        onClick={onClick}
        onMouseEnter={props.onEnter}
        onMouseLeave={props.onLeave}
      >
        <div>
          {subtitle && (
            <Copy size="tiny" mb="small" uppercase tracking="medium">
              {subtitle}
            </Copy>
          )}

          <Heading
            color={muted ? "light" : "base"}
            hover={hover}
            sans={parent ? false : true}
            size={parent ? "base" : "medium"}
            mb="none"
            weight="light"
          >
            {title}
          </Heading>

          {description && <Copy mb="none" html={description} />}
        </div>
        {onClose && <Icon onClick={onClose} name="close" ml="none" mr="none" />}
        {icon && (
          <Icon
            name={icon}
            ml="none"
            mr="none"
            color={hover ? colors.textLight : colors.textBase}
          />
        )}
      </div>
      {children}
    </div>
  );
};

Header.defaultProps = {
  title: null,
  close: false,
  parent: false,
  muted: false,
  icon: null,
  hover: false
};

Header.propTypes = {
  /** Title of Header */
  title: PropTypes.string.isRequired,
  /** Should there be an option to Close the header? */
  close: PropTypes.bool,
  /** Is this a Parent heading? */
  parent: PropTypes.bool
};

Header.styles = styles;

export default Header;
