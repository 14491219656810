import React from "react";
import PropTypes from "prop-types";
import { colors, fontStyles, timing, fonts, fontWeight, letterSpacing, spacing } from "common/styles/variables";
import {rgba} from '../../utils/helpers';
import { Icon } from "components";
import { StyleSheet, css } from "aphrodite";

const hoverStyles = {
  base: {
    textDecoration: "none",
  },

  black: {
    base:{
      color: colors.brandGray,
    },
  },

  gray:{
    base:{
      color: colors.brandWhite,
    },
  },

  white: {
    base:{
      color: colors.brandBlack,
    },
  },

};

const styles = {
  base: {
    position: 'relative',
    appearance: "none",
    backfaceVisibility: "hidden",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: colors.baseTransparent,
    backgroundColor: colors.baseTransparent,
    borderRadius: 0,
    cursor: "pointer",
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: 1,
    textAlign: "center",
    textDecoration: "none",
    fontFamily: fonts.sans,
    fontWeight: fontWeight.regular,
    letterSpacing: letterSpacing.medium,
    textTransform: "uppercase",
    margin: 0,
    padding: 0,
    transition: `color ${timing.slow} ease-in-out,
        opacity ${timing.slow} ease-in-out`,
    verticalAlign: "middle",
    whiteSpace: "nowrap",

    ":hover": hoverStyles.base,
    ":active": hoverStyles.base,
    ":focus": hoverStyles.base,
  },

  color: {
    black: {
      button:{
        color: colors.textDark,
        ":hover": hoverStyles.black.base,
        ":focus": hoverStyles.black.base,
        ":active": hoverStyles.black.base,
      },
      icon:{
        color: colors.textDark,
      },
    },
    white: {
      button:{
        color: colors.textWhite,
        ":hover": hoverStyles.white.base,
        ":focus": hoverStyles.white.base,
        ":active": hoverStyles.white.base,
      },
      icon:{
        color: colors.textWhite,
      },
    },
    gray: {
      button:{
        color: colors.textLight,
        ":hover": hoverStyles.gray.base,
        ":focus": hoverStyles.gray.base,
        ":active": hoverStyles.gray.base,
      },
      icon:{
        color: colors.textLight,
      },
    },
  },

  size: {
    small: {
      button:{
        fontSize: "9px",
      },
      icon:{
        base:{
          fontSize: '13px',
        },
        iconBefore:{
          marginRight: '4px',
          "@media (min-width: 576px)": {
            marginRight: '8px',
          }
        },
        iconAfter:{
          marginLeft: '4px',
          "@media (min-width: 576px)": {
            marginLeft: '8px',
          }
        }
      }
    },
    medium: {
      button:{
        fontSize: "11px",
      },
      icon:{
        base:{
          fontSize: '16px',
        },
        iconBefore:{
          marginRight: '4px',
          "@media (min-width: 576px)": {
            marginRight: '8px',
          }
        },
        iconAfter:{
          marginLeft: '4px',
          "@media (min-width: 576px)": {
            marginLeft: '8px',
          }
        }
      }
    },
    large: {
      button:{
        fontSize: "12px",
      },
      icon:{
        base:{
          fontSize: '20px',
        },
        iconBefore:{
          marginRight: '4px',
          "@media (min-width: 576px)": {
            marginRight: '12px',
          }
        },
        iconAfter:{
          marginLeft: '4px',
          "@media (min-width: 576px)": {
            marginLeft: '12px',
          }
        }
      }
    },
  },

  type: {
    full: {
      justifyContent: 'center',
      width: "100%",
    },
  },

  variant: {
    center:{
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    left:{
      marginRight: 'auto',
    },
    right:{
      marginLeft: 'auto',
    },
  },

  disabled: {
    cursor: "not-allowed",
    opacity: 0.5,
  },

  icon:{
    base:{
      margin: 0,
      padding: 0,
    },
    white:{
      color: colors.textWhite,
    },
    black:{
      color: colors.textBase,
    },
    gray:{
      color: colors.textLight,
    }
  },
}

const Button = (props) => {

  const { href, children, onClick, color, center, left, right, size, target, text, full, disabled, customStyles, iconBefore, iconAfter, iconColor, ml, mr, variant } = props;
  const Element = href ? "a" : "button";
  const role = Element === "a" ? "button" : null;

  const baseStyles = StyleSheet.create({
    button:{
      ...styles.base,
      ...(color && styles.color[color].button),
      ...(size && styles.size[size].button),
      ...(iconBefore && size && styles.size[size].button.iconBefore),
      ...(iconAfter && size && styles.size[size].button.iconAfter),
      ...(full && styles.type.full),
      ...(center && styles.variant.center),
      ...(left && styles.variant.left),
      ...(right && styles.variant.right),
      marginRight: props.mr,
      marginLeft: props.ml,
      ...(customStyles && customStyles),
      ...(disabled && styles.disabled),
    },
    iconBefore:{
      ...styles.icon.base,
      ...(size && styles.size[size].icon.base),
      ...(size && iconBefore && styles.size[size].icon.iconBefore),
      ...(color && styles.color[color].icon),
      ...(iconColor && styles.icon[iconColor]),
    },
    iconAfter:{
      ...styles.icon.base,
      ...(size && styles.size[size].icon.base),
      ...(size && iconAfter && styles.size[size].icon.iconAfter),
      ...(color && styles.color[color].icon),
      ...(iconColor && styles.icon[iconColor]),
    }
  });

  return (
    <Element
      className={css(baseStyles.button)}
      href={href}
      target={target}
      onClick={onClick}
      role={role}
      disabled={disabled}
    >
      {iconBefore &&
        <Icon style={baseStyles.iconBefore} name={iconBefore}/>
      }
      {children &&
        <span>{children}</span>
      }
      {iconAfter &&
        <Icon style={baseStyles.iconAfter} name={iconAfter}/>
      }
    </Element>
  );
}

Button.defaultProps = {
  href: null,
  onClick: null,
  color: "black",
  center: false,
  size: "small",
  full: false,
  target: '_self',
  children: "",
  disabled: false,
  customStyles: null,
  iconBefore: null,
  iconAfter: null,
  iconColor: "black",
  mr:  spacing.space0,
  ml:  spacing.space0,
};

Button.propTypes = {
  /** Pass an href prop to make the button an `a` element instead of a `button` */
  href: PropTypes.string,
  /** Content for the button */
  children: PropTypes.node.isRequired,
  /** Should button be aligned center - needs a flex wrapper*/
  center: PropTypes.bool,
  /** Should button be aligned left - needs a flex wrapper*/
  left: PropTypes.bool,
  /** Should button be aligned right - needs a flex wrapper */
  right: PropTypes.bool,
  /** Function to run when the button is clicked */
  onClick: PropTypes.func,
  /** Color of the button */
  color: PropTypes.oneOf([
    "black",
    "white",
    "gray",
  ]),
  /** Icon Color */
  iconColor: PropTypes.oneOf([
    "black",
    "white",
    "gray",
  ]),
  /** Size of the button */
  size: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]),
  /** Allow button to span available width */
  full: PropTypes.bool,
  /** Special styles passed in props */
  customStyles: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  ),
  /** Disable button */
  disabled: PropTypes.bool,
  /** Icon name to appear before text */
  iconBefore: PropTypes.string,
  /** Icon name to appear after text */
  iconAfter: PropTypes.string,
  /** Margin right in Spacing value as per variables.js */
  mr:  PropTypes.string,
  /** Margin left in Spacing value as per variables.js */
  ml:  PropTypes.string,
  /** Target value for href */
  target:  PropTypes.string,
};

Button.styles = styles;

export default Button;
