import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { colors, spacing, timing } from "common/styles/variables";
import { Copy, POptionImage } from "components";

const styles = {
  base: {
    border: `1px dashed ${colors.borderDark}`,
    backgroundColor: colors.bgWhite,
    borderRadius: spacing.space2,
    padding: spacing.space3,
    marginBottom: spacing.space2,
    marginLeft: spacing.space05,
    marginRight: spacing.space05,
    transition: timing.slow,
    ":hover": {
      cursor: "pointer",
      boxShadow: colors.borderShadow
    }
  }
};

const OptionUpload = props => {
  const { children, onClick, uploaded } = props;

  const baseStyles = StyleSheet.create({
    optionUpload: {
      ...styles.base
    }
  });

  return (
    <div>
      <div className={css(baseStyles.optionUpload)} onClick={onClick}>
        <Copy uppercase mb="none" tracking="medium" size="mini" center>
          {children}
        </Copy>
      </div>
      {uploaded && (
        <POptionImage
          active
          onRemove
          title={"filename.png"}
          image={
            "https://pngimage.net/wp-content/uploads/2018/05/boy-doodle-png.png"
          }
        />
      )}
    </div>
  );
};

OptionUpload.defaultProps = {
  active: false,
  title: null,
  image: null,
  uploaded: false,
  children: "Drop your file here or click to upload"
};

OptionUpload.propTypes = {
  /** Is the option active? */
  active: PropTypes.bool,
  /** Title of the Option */
  title: PropTypes.string,
  /** Descriptive text */
  children: PropTypes.string
};

OptionUpload.styles = styles;

export default OptionUpload;
