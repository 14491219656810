import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Heading } from 'components';
import { fonts, fontStyles, fontWeight, letterSpacing, colors, spacing } from 'common/styles/variables';


const FormHeading = (props) => {

  const { children } = props;

  const styles = StyleSheet.create({
    heading: {
      borderTop: `1px solid ${colors.borderBase}`,
      borderBottom: `1px solid ${colors.borderBase}`,
      paddingTop: spacing.space2,
      paddingBottom: spacing.space2,
      marginTop: spacing.space4,
    }
  });

  return (
    <div className={css(styles.heading)}>
      <Heading sans size="small" uppercase>{children}</Heading>
    </div>
  );
}

export default FormHeading;
