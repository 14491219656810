import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { Copy, Hero, Icon } from "components";
import { StyleSheet, css } from "aphrodite";
import { spacing, colors, timing } from "../../common/styles/variables";

const customStyles = {
  overlay: {
    zIndex: 2000,
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    border: "1px solid",
    borderRadius: 0,
    minWidth: "200px",
    width: "90%",
    maxWidth: "900px",
    maxHeight: "75vh",
    overflowY: "scroll",
    position: "relative"
  }
};

const styles = StyleSheet.create({
  contentStyles: {
    paddingLeft: spacing.space4,
    paddingRight: spacing.space4,
    paddingBottom: spacing.space4
  },
  icon: {
    background: colors.bgLight,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    border: `1px solid ${colors.borderBlack}`,
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: timing.slow,
    ":hover": {
      cursor: "pointer",
      color: colors.brandWhite,
      background: colors.brandBlack
    }
  }
});

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//Modal.setAppElement('#yourAppElement')

class ModalContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    };

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  closeModal() {
    this.setState({ modalIsOpen: false, sent: false });
  }

  render() {
    const { content, children, title } = this.props;
    return (
      <Fragment>
        <Copy onClick={this.openModal} size="small" as="button" mb="none">
          {children}
        </Copy>
        <Modal
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
        >
          <div className={css(styles.icon)} onClick={this.closeModal}>
            <Icon mr="0" ml="0" name="close" fs="12px" height="12px" />
          </div>
          <Hero small title={title}>
            {content}
          </Hero>
          <div className={css(styles.contentStyles)}>
            <Copy html={content} />
          </div>
        </Modal>
      </Fragment>
    );
  }
}
ModalContainer.defaultProps = {
  content: null,
  children: "Click here"
};

export default ModalContainer;
