import React, { Fragment } from "react";
import { StyleSheet, css } from "aphrodite";
import {
  BrandBar,
  Button,
  ButtonText,
  Copy,
  Logo,
  Navbar,
  NavItem
} from "components";
import {
  fonts,
  fontStyles,
  fontWeight,
  colors,
  spacing,
  letterSpacing,
  opacity,
  timing
} from "common/styles/variables";

const Header = props => {
  const { children, condensed } = props;
  const styles = StyleSheet.create({
    header: {
      backgroundColor: colors.bgBlack,
      transform: condensed ? "translateY(-35px)" : "translateY(0)",
      transition: `all ${timing.slow}`,
      paddingTop: "0px",
      zIndex: 999,
      position: "relative"
    },
    brand: {
      borderBottom: !condensed ? `1px solid ${colors.borderDark}` : "none",
      width: "100%",
      display: "block",
      transition: `all ${timing.slow}`
    },
    brandLogo: {
      borderBottom: condensed ? `1px solid ${colors.borderDark}` : "none",
      width: "100%",
      display: "block",
      transition: `all ${timing.slow}`
    },
    main: {
      paddingTop: !condensed ? spacing.space3 : spacing.space1,
      paddingBottom: !condensed ? spacing.space1 : spacing.space2,
      display: "flex",
      justifyContent: "space-between",
      transition: `all ${timing.slow}`
    }
  });

  return (
    <Fragment>
      {/* BrandBar height changed due to branding request. Keeping it here because element will be changed in future */}
      <BrandBar fixed height={!condensed ? 0 : 0} />
      <header className={css(styles.header)}>
        <div className={css(styles.brand)}>
          <Navbar padding={"10px"}>
            {props.backText ? (
              <ButtonText
                target={"_blank"}
                href={props.backLink}
                iconBefore="arrow-left"
                color="gray"
                iconColor="white"
              >
                {props.backText}
              </ButtonText>
            ) : null}
          </Navbar>
        </div>

        <div className={css(styles.brandLogo)}>
          <div className="container">
            <div className={css(styles.main)}>
              <div>{props.renderLogo ? props.renderLogo() : null}</div>
              <div>
                <ButtonText
                  size="large"
                  iconBefore="map"
                  color="gray"
                  iconColor="gray"
                  href="http://americanswiss.co.za/store-locator/"
                  target="_blank"
                >
                  Store Locator
                </ButtonText>
                {props.renderCart ? props.renderCart() : null}
              </div>
            </div>
          </div>
        </div>

        {props.renderNavbar ? props.renderNavbar() : null}
      </header>
    </Fragment>
  );
};

Header.defaultProps = {
  color: "Copy",
  condensed: false
};

export default Header;
