import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { spacing } from "common/styles/variables";
import { Copy, Modal, Icon } from "components";

const styles = {
  base: {
    position: "relative",
    marginBottom: spacing.space2
  },
  hasPadding: {
    paddingRight: spacing.space2,
    paddingLeft: spacing.space2
  },
  baseIcon: {
    paddingRight: spacing.space3
  },
  icon: {
    position: "absolute",
    right: "0px",
    top: "-10px"
  }
};

const Description = props => {
  const { children, info, html, renderModal, hasPadding } = props;

  const baseStyles = StyleSheet.create({
    description: {
      ...styles.base,
      ...(info && styles.baseIcon),
      ...(hasPadding && styles.hasPadding)
    },
    icon: {
      ...styles.icon,
      ":hover": {
        cursor: "pointer"
      }
    }
  });

  return (
    <div
      className={`personalisation_description ${css(baseStyles.description)}`}
    >
      {info && (
        <div className={css(baseStyles.icon)}>
          <Icon name="info" />
        </div>
      )}
      {children && (
        <Copy size="small" mb="none">
          {children}
        </Copy>
      )}
      {html && <Copy size="small" mb="none" html={html} />}
      {renderModal && renderModal}
    </div>
  );
};

Description.defaultProps = {
  children: null,
  info: false,
  hasPadding: false,
  html: null
};

Description.propTypes = {
  /** Children of Description */
  children: PropTypes.string.isRequired,
  /** Is there more info to display? */
  info: PropTypes.bool,
  /** Is there padding on the text */
  hasPadding: PropTypes.bool,
  /** HTML of Description */
  html: PropTypes.string
};

Description.styles = styles;

export default Description;
