import React from "react";
import PropTypes from "prop-types";
import { flatten } from "ramda";
import { StyleSheet, css } from "aphrodite";
import {
  fonts,
  fontStyles,
  fontWeight,
  letterSpacing,
  colors,
  spacing
} from "common/styles/variables";
import { Copy, Heading } from "components";

const styles = {
  base: {
    // paddingTop: spacing.space2,
    // paddingRight: spacing.space1,
    // paddingBottom: spacing.space3,
    // paddingLeft: spacing.space1,
    backgroundColor: colors.bgWhite
    // borderBottom: `1px solid ${colors.borderBase}`
  },
  active: {
    // backgroundColor: colors.bgLight,
    backgroundColor: colors.bgWhite
  },
  hasPadding: {
    paddingRight: spacing.space2,
    paddingBottom: spacing.space2,
    paddingLeft: spacing.space2
  }
};

const Body = props => {
  const { children, active, hasPadding } = props;

  const baseStyles = StyleSheet.create({
    body: {
      ...styles.base,
      ...(active && styles.active),
      ...(hasPadding && styles.hasPadding)
    }
  });

  return <div className={css(baseStyles.body)}>{children}</div>;
};

Body.defaultProps = {
  children: null,
  active: false,
  hasPadding: false
};

Body.propTypes = {
  /** Children of Body */
  children: PropTypes.string,
  /** Is the Body active? */
  active: PropTypes.bool,
  /** Does the body have padding? */
  hasPadding: PropTypes.bool
};

Body.styles = styles;

export default Body;
