import React, { Component } from "react";
import PropTypes from "prop-types";
import Media from "react-media";
import { StyleSheet, css } from "aphrodite";
import { Heading, Copy } from "components";
import { colors, spacing } from "common/styles/variables";
import { styleConvert } from "../../utils/helpers";

class Hero extends Component {
  render() {
    const {
      large,
      small,
      modal,
      title,
      subTitle,
      description,
      image
    } = this.props;

    const styles = {
      wrapper: {
        base: {
          paddingTop: spacing.space2,
          paddingBottom: spacing.space2,
          marginBottom: spacing.space2,
          borderBottom: `1px solid ${colors.borderBase}`,
          "@media (min-width: 576px)": {
            paddingTop: spacing.space6,
            paddingBottom: spacing.space6,
            marginBottom: spacing.space6
          }
        },
        image: {
          background: image ? `url("${image.url}")` : null,
          backgroundSize: "cover",
          backgroundPosition: image
            ? `${image.hotspot && image.hotspot[0]} ${image.hotspot &&
                image.hotspot[1]}`
            : null,
          backgroundRepeat: "no-repeat"
        },
        large: {
          paddingTop: spacing.space6,
          paddingBottom: spacing.space6
        },
        small: {
          "@media (min-width: 576px)": {
            paddingTop: spacing.space6,
            paddingBottom: spacing.space6
          }
        },
        modal: {
          marginBottom: spacing.space2,
          "@media (min-width: 576px)": {
            marginBottom: spacing.space2,
            paddingTop: spacing.space2,
            paddingBottom: spacing.space2
          }
        }
      },

      hero: {
        base: {
          paddingTop: spacing.space4,
          paddingRight: spacing.space2,
          paddingBottom: spacing.space4,
          paddingLeft: spacing.space2,
          border: `${spacing.space1} solid ${colors.borderBlack}`,
          backgroundColor: colors.bgWhiteO,
          maxWidth: "770px",
          margin: "auto",
          "@media (min-width: 576px)": {
            paddingTop: spacing.space6,
            paddingRight: spacing.space3,
            paddingLeft: spacing.space3,
            paddingBottom: spacing.space6
          }
        },
        modal: {
          paddingTop: spacing.space3,
          paddingBottom: spacing.space2,
          "@media (min-width: 576px)": {
            paddingTop: spacing.space3,
            paddingBottom: spacing.space2
          }
        },
        image: {
          backgroundColor: colors.bgBlackO,
          border: `${spacing.space1} solid ${colors.borderWhite}`,
          maxWidth: "100%"
        }
      }
    };

    const wrapper = [
      styles.wrapper.base,
      image && styles.wrapper.image,
      large && styles.wrapper.large,
      small && styles.wrapper.small,
      modal && styles.wrapper.modal
    ];

    const hero = [
      styles.hero.base,
      image && styles.hero.image,
      modal && styles.hero.modal
    ];

    const baseStyles = StyleSheet.create({
      wrapper: styleConvert(wrapper),
      hero: styleConvert(hero)
    });

    return (
      <div className={`HeroWrapper ${css(baseStyles.wrapper)}`}>
        <div className="container">
          <div className={css(baseStyles.hero)}>
            {subTitle && (
              <Copy
                center
                uppercase
                mb="small"
                tracking="medium"
                html={subTitle}
                color={image && "white"}
              />
            )}
            <Media query={{ minWidth: 576 }}>
              {matches =>
                matches ? (
                  <Heading
                    center
                    size={small || modal ? "large" : "huge"}
                    mb={`${description ? "small" : "none"}`}
                    html={title}
                    color={image && "white"}
                  />
                ) : (
                  <Heading
                    center
                    size="large"
                    mb={`${description ? "small" : "none"}`}
                    html={title}
                    color={image && "white"}
                  />
                )
              }
            </Media>
            {description && (
              <Copy
                center
                size="large"
                mb="none"
                html={description}
                color={image && "white"}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Hero.defaultProps = {
  title: null,
  large: false,
  small: false,
  modal: false,
  subTitle: null,
  description: null,
  image: null
};

Hero.propTypes = {
  /** The Title */
  title: PropTypes.string.isRequired,
  /** The Sub Title to appear above the Title */
  subTitle: PropTypes.string,
  /** The Description to appear below the Title */
  description: PropTypes.string,
  /** Is this a Large Hero? */
  large: PropTypes.bool,
  /** Is this a Hero for a Modal? */
  modal: PropTypes.bool,
  /** Is this a Small Hero? */
  small: PropTypes.bool,
  /** The URL of the image of the Hero */
  image: PropTypes.object
};

export default Hero;
