import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { Heading, Image, Icon, Button, Copy } from "components";
import {
  fonts,
  fontStyles,
  fontWeight,
  letterSpacing,
  colors,
  spacing
} from "common/styles/variables";

const ProductRow = props => {
  const { minHeight } = props;

  const styles = StyleSheet.create({
    row: {
      borderBottom: `1px solid ${colors.borderBase}`,
      paddingTop: spacing.space3,
      paddingBottom: spacing.space3
    }
  });

  return (
    <div className={css(styles.row)}>
      <div className="row align-items-center">
        <div className="col-5 col-sm-2 col-md-2">
          {props.image ? <Image imageUrl={props.image} /> : null}
        </div>
        <div className="col-7 col-md-5">
          <Heading size="small" uppercase mb="tiny">
            {props.title}
          </Heading>
          <Heading sans size="tiny" uppercase>
            {props.total}
          </Heading>
          {props.renderMeta ? props.renderMeta() : null}
        </div>
        <div className="col-12 col-md-5 justify-content-between justify-content-md-end d-flex ml-auto">
          {props.onDuplicate && (
            <Button onClick={props.onDuplicate} outline>
              Duplicate
            </Button>
          )}
          {props.onEdit && (
            <Button onClick={props.onEdit} outline ml={spacing.space2}>
              Edit
            </Button>
          )}
          {props.onRemove && (
            <Button
              onClick={props.onRemove}
              outline
              ml={spacing.space2}
              customStyles={{ paddingLeft: "8px", paddingRight: "8px" }}
            >
              <Icon name="close" ml="0px" mr="0px" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductRow;
