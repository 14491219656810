import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { rgba } from "../../../utils/helpers";
import {
  fonts,
  fontStyles,
  fontWeight,
  colors,
  spacing,
  letterSpacing,
  timing
} from "../../../common/styles/variables";

const NavItem = props => {
  const { children, href } = props;

  const styles = StyleSheet.create({
    navItem: {
      color: props.color,
      marginTop: props.mt,
      marginRight: props.mr,
      marginBottom: props.mb,
      marginLeft: props.ml,
      display: props.display,
      fontWeight: props.fontWeight,
      fontFamily: fonts.sans,
      letterSpacing: props.letterSpacing,
      textTransform: props.textTransform,
      listStyle: "none",
      textAlign: props.textAlign,
      ...fontStyles(props.fs, props.lh),
      transition: `all ${timing.slow} ease-in-out`,
      ":hover": {
        color: props.colorHover ? props.colorHover : rgba(props.color, 0.5),
        textDecoration: "none"
      }
    },
    link: {
      color: props.color,
      textDecoration: "none",
      transition: `all ${timing.slow} ease-in-out`,
      ":hover": {
        color: props.colorHover ? props.colorHover : rgba(props.color, 0.5)
      }
    }
  });

  return (
    <li className={css(styles.navItem)}>
      {href && (
        <a href={href} title={children} className={css(styles.link)}>
          {props.children}
        </a>
      )}
      {!href && props.children}
    </li>
  );
};

NavItem.defaultProps = {
  color: colors.linkBase,
  colorHover: null,
  display: "inline-block",
  fontWeight: fontWeight.regular,
  href: null,
  fs: "12px",
  lh: "12px",
  textTransform: "uppercase",
  letterSpacing: letterSpacing.small,
  mt: spacing.space0,
  mr: spacing.space2,
  mb: spacing.space0,
  ml: spacing.space0,
  textAlign: "center"
};

NavItem.propTypes = {
  /** Text color as per variables.js */
  color: PropTypes.string.isRequired,
  /** Text color as per variables.js */
  colorHover: PropTypes.string.isRequired,
  /** Display property as per CSS */
  display: PropTypes.string,
  /** Font weight as per CSS */
  fontWeight: PropTypes.string,
  /** Text Transformation */
  textTransform: PropTypes.string,
  /** Letter Spacing */
  letterSpacing: PropTypes.string,
  /** URL to link */
  href: PropTypes.string,
  /** Font size as pixel value */
  fs: PropTypes.string,
  /** Line height as pixel value */
  lh: PropTypes.string,
  /** Margin top in Spacing value as per variables.js */
  mt: PropTypes.string,
  /** Margin right in Spacing value as per variables.js */
  mr: PropTypes.string,
  /** Margin bottom in Spacing value as per variables.js */
  mb: PropTypes.string,
  /** Margin left in Spacing value as per variables.js */
  ml: PropTypes.string,
  /** Sets the alignment of the text item */
  textAlign: PropTypes.string
};

export default NavItem;
