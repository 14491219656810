import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Heading, Card, Copy, Button, Image, Section, SliderWrapper } from 'components';
import { fonts, fontStyles, fontWeight, letterSpacing, colors, spacing } from 'common/styles/variables';

const ProductSlider = (props) => {

  const { title, children} = props;

  const styles = {
    slider:{
      marginTop: spacing.space5,
      borderTop: `1px solid ${colors.borderBase}`,
    },
    slide:{
      paddingLeft: spacing.space3,
      paddingRight: spacing.space3,
    },
  }


  const baseStyles = StyleSheet.create({
    slider:{
      ...styles.slider,
    },
    slide:{
      ...styles.slide,
    },
  });

  return (
    <div className={css(baseStyles.slider)}>
      <Section mt="small" mb="none" container="container-fluid">
        <div className="col-md-12">
          <Heading uppercase sans center size="medium" mb="large">{title}</Heading>
        </div>
        <div className="col-md-12">
          <SliderWrapper>
            { props.children }
          </SliderWrapper>
        </div>
      </Section>
    </div>
  );
}

ProductSlider.defaultProps = {
  title: "Other Prducts you might like",
  children: null,
}

ProductSlider.propTypes = {
  /** Title of the Product Slider */
  title: PropTypes.string,
}

export default ProductSlider;
