import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Heading, Copy } from 'components';
import { fonts, fontStyles, fontWeight, letterSpacing, colors, spacing, timing } from 'common/styles/variables';


const BrandBar = (props) => {

  const totalWidth = (width) => {
    let double = 0;
    double = width * 2;
    return double;
  }
  const { height, width, fixed } = props;

  const fixedProps = {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: '1000'
  }

  const styles = StyleSheet.create({
    bar: {
      color: colors.brandBlack,
      height: `${height}px`,
      background: `repeating-linear-gradient(to right,#ffffff,#ffffff ${width}px,#000000 ${width}px,#000000 ${totalWidth(width)}px)`,
      transition: `all ${timing.slow}`,
      ...(fixed ? fixedProps : null)
    },
  });

  return (
    <div className={css(styles.bar)}></div>
  );
}

BrandBar.defaultProps = {
  height: 20,
  width: 32,
  fixed: false,
}

BrandBar.propTypes = {
  /** Height of the bar(in px) */
  height: PropTypes.number,
  /** Width of each section(in px) */
  width: PropTypes.number,
}

export default BrandBar;
