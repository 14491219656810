import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const Loading = props => {
  const { minHeight } = props;

  const styles = StyleSheet.create({
    loader: {
      minHeight: minHeight ? "100vh" : null,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    gif: {
      width: "30px",
      height: "30px"
    }
  });

  return (
    <div className={css(styles.loader)}>
      <img
        className={css(styles.gif)}
        src={require("../../common/images/loading.gif")}
        alt="loading..."
      />
    </div>
  );
};

Loading.defaultProps = {
  minHeight: true
};

Loading.propTypes = {
  minHeight: PropTypes.bool
};

export default Loading;
