import React from "react";
import PropTypes from "prop-types";
import { flatten } from "ramda";
import { StyleSheet, css } from "aphrodite";
import {
  fonts,
  fontStyles,
  fontWeight,
  letterSpacing,
  colors,
  spacing,
  timing
} from "common/styles/variables";
import { Copy } from "components";

const styles = {
  base: {
    borderBottom: `1px solid transparent`,
    // backgroundColor: colors.bgWhite,
    // borderRadius: spacing.space2,
    // paddingLeft: spacing.space2,
    // paddingRight: spacing.space2,
    // marginRight: spacing.space1,
    transition: timing.slow,
    ":hover": {
      cursor: "pointer",
      // boxShadow: colors.borderShadow,
      borderBottom: `1px solid ${colors.borderDark}`
    }
  },
  active: {
    borderBottom: `1px solid ${colors.borderDark}`
  }
};

const Tab = props => {
  const { active, ml, children, onClick } = props;

  const baseStyles = StyleSheet.create({
    tab: {
      ...styles.base,
      ...(active && styles.active),
      ...(ml && { marginLeft: ml })
    }
  });

  return (
    <div className={css(baseStyles.tab)} onClick={onClick}>
      <Copy weight="normal" mb="none" size={"small"} center>
        {children}
      </Copy>
    </div>
  );
};
Tab.defaultProps = {
  active: false,
  children: null,
  onClick: null
};

Tab.propTypes = {
  /** Is the option active? */
  active: PropTypes.bool
};

Tab.styles = styles;

export default Tab;
