import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { styleConvert } from "../../utils/helpers";
import Media from "react-media";
import { Heading, Copy } from "components";
import { colors, spacing } from "common/styles/variables";

const Billboard = props => {
  const { title, children, description, small, border } = props;

  const styles = {
    wrapper: {
      base: {
        background: colors.bgBlack,
        paddingTop: spacing.space4,
        paddingBottom: spacing.space2,
        borderBottom: `1px solid ${colors.borderDark}`,
        "@media (min-width: 576px)": {
          paddingTop: spacing.space6,
          paddingBottom: spacing.space6
        }
      },
      small: {
        paddingTop: spacing.space2,
        paddingBottom: spacing.space2,
        "@media (min-width: 576px)": {
          paddingTop: spacing.space2,
          paddingBottom: spacing.space2
        }
      }
    },
    billboard: {
      base: {
        padding: spacing.space0,
        maxWidth: "570px",
        margin: "auto",
        "@media (min-width: 576px)": {
          padding: spacing.space3
        }
      },
      border: {
        border: "8px solid #fff"
      }
    }
  };

  const wrapper = [styles.wrapper.base, small && styles.wrapper.small];
  const billboard = [styles.billboard.base, border && styles.billboard.border];

  const baseStyles = StyleSheet.create({
    wrapper: styleConvert(wrapper),
    billboard: styleConvert(billboard)
  });

  return [
    <div className={css(baseStyles.wrapper)}>
      <div className="container">
        <div className={css(baseStyles.billboard)}>
          <Media query="(min-width: 576px)">
            {matches =>
              matches ? (
                <Heading
                  center
                  size={small ? "large" : "huge"}
                  mb="small"
                  color="white"
                  html={title}
                />
              ) : (
                <Heading
                  center
                  size="medium"
                  mb="small"
                  color="white"
                  html={title}
                />
              )
            }
          </Media>
          {description && (
            <Copy center size="large" mb="large" color="white">
              {description}
            </Copy>
          )}
          {children}
        </div>
      </div>
    </div>
  ];
};

Billboard.defaultProps = {
  title: null,
  subTitle: null,
  description: null,
  small: false,
  border: false,
  children: null
};

Billboard.propTypes = {
  /** The Title */
  title: PropTypes.string,
  /** The Sub Title to appear above the Title */
  subTitle: PropTypes.string,
  /** The Description to appear below the Title */
  description: PropTypes.string,
  /** Is this a small Billboard? */
  small: PropTypes.bool,
  /** Is this a bordered Billboard? */
  border: PropTypes.bool
};

export default Billboard;
