import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";

const styles = {
  base: {
    display: "flex",
    justifyContent: "flex-start",
    // marginTop: spacing.space2,
    flexWrap: "wrap"
  }
};

const OptionsGrid = props => {
  const { children, justify = null } = props;

  const baseStyles = StyleSheet.create({
    optionsGrid: {
      ...styles.base,
      ...(justify ? { justifyContent: justify } : {})
    }
  });

  return <div className={css(baseStyles.optionsGrid)}>{children}</div>;
};

OptionsGrid.defaultProps = {
  items: null
};

OptionsGrid.propTypes = {
  /** Items of OptionsGrid */
  items: PropTypes.string
};

OptionsGrid.styles = styles;

export default OptionsGrid;
