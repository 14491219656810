import React from "react";
import PropTypes from "prop-types";
import { flatten } from "ramda";
import { StyleSheet, css } from "aphrodite";
import {
  colors,
  fonts,
  fontWeight,
  fontStyles,
  letterSpacing,
  spacing
} from "common/styles/variables";

const hoverStyles = {
  base: {
    textDecoration: "none",
    color: colors.textBaseHover
  },
  light: {
    textDecoration: "none",
    color: colors.textLightHover
  },
  white: {
    color: colors.textWhiteHover
  }
};

const styles = {
  base: {
    lineHeight: 1,
    margin: 0,
    padding: 0,
    fontFamily: fonts.serif
  },

  size: {
    tiny: {
      ...fontStyles("12px", `${12 * 1.25}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${12 * 1}px`, `${12 * 1.25}px`)
      }
    },
    small: {
      ...fontStyles("14px", `${14 * 1.25}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${14 * 1}px`, `${14 * 1.25}px`)
      }
    },
    medium: {
      ...fontStyles("16px", `${16 * 1.25}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${16 * 1}px`, `${16 * 1.25}px`)
      }
    },
    base: {
      ...fontStyles("20px", `${20 * 1.25}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${20 * 1}px`, `${20 * 1.25}px`)
      }
    },
    large: {
      ...fontStyles("30px", `${30 * 1.25}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${30 * 1}px`, `${30 * 1.25}px`)
      }
    },
    huge: {
      ...fontStyles("40px", `${40 * 1.25}px`),
      "@media (max-width: 576px)": {
        ...fontStyles(`${40 * 1}px`, `${40 * 1.25}px`)
      }
    }
  },

  mb: {
    none: {
      marginBottom: spacing.space0
    },
    tiny: {
      marginBottom: spacing.space1
    },
    small: {
      marginBottom: spacing.space2
    },
    medium: {
      marginBottom: spacing.space4
    },
    large: {
      marginBottom: spacing.space6
    },
    huge: {
      marginBottom: spacing.space9
    }
  },

  weight: {
    light: {
      fontWeight: fontWeight.light
    },
    regular: {
      fontWeight: fontWeight.regular
    },
    medium: {
      fontWeight: fontWeight.medium
    },
    bold: {
      fontWeight: fontWeight.bold
    }
  },

  hover: {
    cursor: "pointer"
  },

  color: {
    base: {
      color: colors.textBase,
      hover: {
        ":hover": hoverStyles.base,
        ":focus": hoverStyles.base,
        ":active": hoverStyles.base
      }
    },
    light: {
      color: colors.textLight,
      hover: {
        ":hover": hoverStyles.light,
        ":focus": hoverStyles.light,
        ":active": hoverStyles.light
      }
    },
    white: {
      color: colors.textWhite,
      hover: {
        ":hover": hoverStyles.white,
        ":focus": hoverStyles.white,
        ":active": hoverStyles.white
      }
    }
  },

  variant: {
    serif: {
      fontFamily: fonts.serif
    },
    sans: {
      fontFamily: fonts.sans
    },
    uppercase: {
      textTransform: "uppercase"
    },
    truncate: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    noWrap: {
      whiteSpace: "nowrap"
    },
    left: {
      textAlign: "left"
    },
    right: {
      textAlign: "right"
    },
    center: {
      textAlign: "center"
    },
    inline: {
      display: "inline-block"
    }
  },

  tracking: {
    none: {
      letterSpacing: letterSpacing.none
    },
    small: {
      letterSpacing: letterSpacing.small
    }
  }
};

const Heading = props => {
  const {
    color,
    children,
    hover,
    inline,
    level,
    html,
    tracking,
    mb,
    override,
    size,
    serif,
    sans,
    truncate,
    noWrap,
    uppercase,
    left,
    right,
    center,
    weight
  } = props;

  const Component = level !== "text" ? `h${level}` : level;

  const style = [
    styles.base,
    serif && styles.variant.serif,
    sans && styles.variant.sans,
    size && styles.size[size],
    mb && styles.mb[mb],
    weight && styles.weight[weight],
    color && styles.color[color],
    hover && styles.hover,
    hover && styles.color[color].hover,
    tracking && styles.tracking[tracking],
    truncate && styles.variant.truncate,
    noWrap && styles.variant.noWrap,
    uppercase && styles.variant.uppercase,
    left && styles.variant.left,
    right && styles.variant.right,
    center && styles.variant.center,
    inline && styles.variant.inline,
    override && override
  ];

  const temp = StyleSheet.create({
    heading: style.reduce((result, item) => {
      if (item) {
        return {
          ...result,
          ...item
        };
      }
      return result;
    }, {})
  });

  if (html) {
    return (
      <div
        className={css(temp.heading)}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  }

  return <Component className={css(temp.heading)}>{children}</Component>;
};

Heading.defaultProps = {
  color: "base",
  sans: false,
  serif: true,
  hover: false,
  level: 2,
  tracking: "small",
  mb: "none",
  override: {},
  size: "base",
  truncate: false,
  noWrap: false,
  uppercase: false,
  left: true,
  right: false,
  center: false,
  inline: false,
  weight: "regular"
};

Heading.propTypes = {
  /** The heading color*/
  color: PropTypes.oneOf(["base", "blue", "red", "white", "light"]),
  /** Text for the heading */
  children: PropTypes.node,
  /** Creates the heading HTML element */
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6, "text"]).isRequired,
  /** Controls the letter spacing */
  tracking: PropTypes.oneOf(["none", "small"]),
  /** Margin bottom  */
  mb: PropTypes.oneOf(["none", "tiny", "small", "medium", "large"]),
  /** Override styles */
  override: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  ),
  /** Declares the font size of the heading */
  size: PropTypes.oneOf(["huge", "large", "medium", "base", "small", "tiny"]),
  /** Is the font serif? */
  serif: PropTypes.bool,
  /** Is the font sans? */
  sans: PropTypes.bool,
  /** Whether or not to change the color on hover */
  hover: PropTypes.bool,
  /** Whether or not to hide the text overflow with an ellipsis */
  truncate: PropTypes.bool,
  /** Whether or not to prevent the text from wrapping */
  noWrap: PropTypes.bool,
  /** Whether or not to set the heading in all caps */
  uppercase: PropTypes.bool,
  /** Whether or not to align left */
  left: PropTypes.bool,
  /** Whether or not to align right */
  right: PropTypes.bool,
  /** Whether or not to align center */
  center: PropTypes.bool,
  /** Whether or not to apply inline-block display property */
  inline: PropTypes.bool,
  /** Adjusts the font weight of the heading */
  weight: PropTypes.oneOf(["bold", "regular", "medium", "light"])
};

Heading.styles = styles;

export default Heading;
