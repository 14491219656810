import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import { styleConvert } from "../../utils/helpers";
import { Heading, Copy } from "components";
import { fonts, colors, spacing, timing } from "common/styles/variables";

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false
    };
  }

  toggleItem = () => {
    this.setState({
      hover: !this.state.hover
    });
  };

  render() {
    const {
      title,
      price,
      description,
      alt,
      vertical,
      landscape,
      portrait,
      image,
      renderCta,
      eyeCatcher,
      hover,
      mb
    } = this.props;

    const styles = {
      card: {
        base: {
          marginBottom: mb,
          ":hover": {
            cursor: "pointer"
          }
        }
      },
      content: {
        base: {
          paddingTop: spacing.space3,
          paddingBottom: spacing.space3
        }
      },
      text: {
        base: {
          paddingRight: spacing.space3,
          paddingLeft: spacing.space3
        }
      },
      imageWrapper: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative"
      },

      eyeCatcherStyle: {
        zIndex: "99",
        lineHeight: "1",
        textAlign: "center",
        fontFamily: fonts.sans,
        fontSize: "12px",
        margin: 0,
        padding: 0,
        background: colors.baseWhite,
        padding: "1em 1em",
        position: "absolute",
        top: 0,
        right: 0,
        transform: "translateX(30%) translateY(0%) rotate(45deg)",
        transformOrigin: "top left",
        "::before": {
          content: "''",
          position: "absolute",
          top: "0px",
          margin: "0 -1px",
          width: "100%",
          height: "100%",
          background: colors.baseWhite,
          right: "100%"
        },
        "::after": {
          content: "''",
          position: "absolute",
          top: "0px",
          margin: "0 -1px",
          width: "100%",
          height: "100%",
          background: colors.baseWhite,
          left: "100%"
        }
      },
      img: {
        base: {
          ...(image
            ? {
                background: `url("${image.url}")`,
                backgroundSize: "cover",
                backgroundPosition: `${image.hotspot[0]} ${image.hotspot[1]}`,
                backgroundRepeat: "no-repeat"
              }
            : {}),
          transition: `transform ${timing.slow} ease-out, opacity ${timing.slow} ease-out`,
          position: "relative",
          opacity: 1
        },
        hover: {
          transform: "scale(1.1)",
          opacity: 1
        },
        landscape: {
          paddingBottom: "60%",
          "@media (min-width: 576px)": {
            paddingBottom: "48%"
          }
        },
        portrait: {
          paddingBottom: "128%",
          "@media (min-width: 576px)": {
            paddingBottom: "128%"
          }
        }
      },
      effect: {
        base: {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: `1px solid ${colors.brandWhite}`,
          borderWidth: "0",
          transition: `border-width ${timing.slow} ease-out`
        },
        hover: {
          borderWidth: spacing.space1
        }
      },
      buttons: {
        display: "flex",
        width: "100%",
        justifyContent: "space-around"
      }
    };

    const card = [styles.card.base];
    const content = [styles.content.base];
    const text = [styles.text.base];
    const imageWrapper = [styles.imageWrapper];
    const eyeCatcherStyle = [styles.eyeCatcherStyle];
    const img = [
      styles.img.base,
      landscape && styles.img.landscape,
      portrait && styles.img.portrait,
      this.state.hover && styles.img.hover
    ];
    const effect = [
      styles.effect.base,
      this.state.hover && styles.effect.hover
    ];
    const buttons = [styles.buttons];

    const baseStyles = StyleSheet.create({
      card: styleConvert(card),
      content: styleConvert(content),
      text: styleConvert(text),
      imageWrapper: styleConvert(imageWrapper),
      eyeCatcherStyle: styleConvert(eyeCatcherStyle),
      img: styleConvert(img),
      effect: styleConvert(effect),
      buttons: styleConvert(buttons)
    });

    return (
      <div
        className={css(baseStyles.card)}
        onMouseEnter={() => this.toggleItem()}
        onMouseLeave={() => this.toggleItem()}
      >
        <div className={`row ${alt && !vertical && "flex-row-reverse"}`}>
          {image && (
            <div className={`${vertical ? "col-md-12" : "col-md-9"}`}>
              <div className={css(baseStyles.imageWrapper)}>
                {eyeCatcher && (
                  <div className={css(baseStyles.eyeCatcherStyle)}>
                    {eyeCatcher}
                  </div>
                )}
                <div className={css(baseStyles.img)} />
                <div className={css(baseStyles.effect)} />
              </div>
            </div>
          )}
          <div
            className={`${
              vertical ? "col-md-12" : "col-md-3"
            } d-flex align-items-center justify-content-center ${css(
              baseStyles.content
            )}`}
          >
            <div className={css(baseStyles.text)}>
              {title && (
                <Heading center size="small" mb="tiny">
                  {title}
                </Heading>
              )}
              {price && (
                <Copy center size="tiny" weight="bold" mb="large">
                  {price}
                </Copy>
              )}
              {description && <Copy center mb="large" html={description} />}
              {renderCta ? (
                <div className={css(baseStyles.buttons)}>
                  {renderCta && renderCta(this.state.hover)}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  alt: false,
  vertical: false,
  portrait: false,
  landscape: true,
  title: null,
  price: null,
  description: null,
  image: null,
  eyeCatcher: null,
  mb: spacing.space5
};

Card.propTypes = {
  /** Changes the layout of the alternate version of the card eg image left, text right */
  alt: PropTypes.bool,
  /** Changes the layout of the card to vertical orientation - Image above text */
  vertical: PropTypes.bool,
  /** Sets the image as portrait orientation */
  portrait: PropTypes.bool,
  /** Sets the image as landscape orientation */
  landscape: PropTypes.bool,
  /** The title of the Card */
  title: PropTypes.string.isRequired,
  /** The Price of the Card */
  price: PropTypes.string,
  /** The description of the Card */
  description: PropTypes.string,
  /** The URL of the image of the card */
  image: PropTypes.object,
  //** */ Eye catcher to draw attention */
  eyeCatcher: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Card;
