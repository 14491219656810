import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Heading } from 'components';
import { fonts, fontStyles, fontWeight, letterSpacing, colors, spacing } from 'common/styles/variables';


const FormBox = (props) => {

  const { children } = props;

  const styles = StyleSheet.create({
    box: {
      padding: spacing.space2,      
      marginBottom: spacing.space4,
      background: colors.bgLight,
    }
  });

  return (
    <div className={css(styles.box)}>
      {children}
    </div>
  );
}

export default FormBox;
